<template>
	<div class="wrap">
		<el-table :data="tableData" v-loading="loading" max-height="9999">
			<el-table-column type="index" width="50"></el-table-column>

			<el-table-column prop="operation_type" label="操作类型" width="100">
				<template slot-scope="scope">
					<div class="text-green" v-if="scope.row.operation_type == 1">入库</div>
					<div class="text-red" v-if="scope.row.operation_type == 2">出库</div>
				</template>
			</el-table-column>
			<el-table-column prop="number" label="数量" width="100">
				<template slot-scope="scope">
					<div class="text-green" v-if="scope.row.operation_type == 1">+{{scope.row.number}}</div>
					<div class="text-red" v-if="scope.row.operation_type == 2">-{{scope.row.number}}</div>
				</template>
			</el-table-column>
			<el-table-column prop="goods_name" label="商品名称" width="300"></el-table-column>
			<el-table-column prop="type" label="出入库类型" width="100">
				<template slot-scope="scope">
					<!-- 出入库类型： 1:进货 2：报溢 3：退货 4：销售 5：报损 6：自用 7：其他 -->
					<div v-if="scope.row.type == 1">进货</div>
					<div v-if="scope.row.type == 2">报溢</div>
					<div v-if="scope.row.type == 3">退货</div>
					<div v-if="scope.row.type == 4">销售</div>
					<div v-if="scope.row.type == 5">报损</div>
					<div v-if="scope.row.type == 6">自用</div>
					<div v-if="scope.row.type == 7">其他</div>
				</template>
			</el-table-column>
			<el-table-column prop="remark" label="备注" width="200"></el-table-column>
			<el-table-column prop="operator" label="操作人" width="100"></el-table-column>
			<el-table-column prop="created_at" label="时间"></el-table-column>
		</el-table>
		<div class="text-center margin-top">
			<el-pagination @current-change="pageChange" :current-page="searchForm.page" :page-sizes="vuex_page_sizes" :page-size="searchForm.page_size"
			layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="pageSizeChange">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				loading: false,
				searchForm: {
					page: 1,
					page_size: 10
				},
				total: 0,
				tableData: []
			};
		},
		mounted() {
			this.search();
		},
		methods: {
			search() {
				this.loading = true;
				this.$api.stock.log(this.searchForm).then().then(res => {
					this.loading = false;
					this.tableData = res.data.data;
					this.total = res.data.count;
				});
			},
			pageSizeChange(size) {
				this.searchForm.page_size = size;
				this.search()
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.search()
			},
		}
	};
</script>
<style scoped lang="scss">

</style>
